import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./components/common/components/Loading";
import PrivateRoutes from "./utils/PrivateRoutes";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";
import AdminRoutes from "./utils/AdminRoutes";

const Home = lazy(() => import("./pages/Home"));
const PageNotFound = lazy(() => import("./pages/PageNotFoundPage"));
const TestBoardPage = lazy(() => import("./pages/TestBoardPage"));
const TestResultDetailsPage = lazy(() =>
  import("./pages/TestResultDetailsPage")
);
const TestResultListPage = lazy(() => import("./pages/TestResultListPage"));
const HandleTestAccess = lazy(() => import("./pages/HandleTestAccess"));
const LoginPage = lazy(() => import("./pages/GrowDVLoginPage"));
const SignUpPage = lazy(() => import("./pages/GrowDVSignUpPage"));
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPasswordPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ServicesPage = lazy(() => import("./pages/ServicesPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const FAQPage = lazy(() => import("./pages/FAQPage"));
const PaymentCompletion = lazy(() => import("./pages/PaymentCompletion"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsOfService"));
const CancellationOrRefund = lazy(() => import("./pages/CancellationOrRefund"));
const UnSubscribeEmail = lazy(() => import("./pages/UnSubscribeEmail"));
const TestDetailPage = lazy(() => import("./pages/TestDetailPage"));
const BuyTestInfo = lazy(() => import("./pages/BuyTestInfo"));
const VerifSudha = lazy(() => import("./pages/VerifSudha"));
const TestReviewPage = lazy(() => import("./pages/ReviewPage"));
const CertificatePage = lazy(() => import("./pages/CertificatePage"));
const UpdateProfilePage = lazy(() => import("./pages/UpdateProfilePage"));
const UserTestsPage = lazy(() => import("./pages/UserTestsPage"));
const VimeoVideos = lazy(() => import("./pages/VimeoVideos"));
const CoursesPage = lazy(() => import("./pages/CoursesPage"));
const CourseDetailPage = lazy(() => import("./pages/CourseDetailPage"));
const BuyCourseInfo = lazy(() => import("./pages/BuyCourseInfo"));
const UserCoursesPage = lazy(() => import("./pages/UserCoursesPage"));
const UserChaptersPage = lazy(() => import("./pages/UserChaptersPage"));
const UsersContentsPage = lazy(() => import("./pages/UsersContentsPage"));
const UserContentPage = lazy(() => import("./pages/UserContentPage"));
const SafalMetaVLSILogin = lazy(() => import("./pages/SafalMetaVLSILogin"));
const SafalMetaVLSISignUp = lazy(() => import("./pages/SafalMetaVLSISignUp"));
const CourseTest = lazy(() => import("./pages/CourseTest"));
const AdminCoursesPage = lazy(() => import("./pages/AdminCourseProgressPage"));
const AdminChapterProgressView = lazy(() =>
  import("./pages/AdminChapterProgressView")
);
const AdminContentProgressPage = lazy(() =>
  import("./pages/AdminContentProgressPage")
);
const AdminPage = lazy(() => import("./pages/AdminPage"));
const HandleCourseAccess = lazy(() => import("./pages/HandleCourseAccess"));
const TestsPage = lazy(() => import("./pages/TestsPage"));
const PurchasePage = lazy(() => import("./pages/PurchasePage"));
const UserDashboardPage = lazy(() => import("./pages/UserDashboardPage"));
const UserTransactions = lazy(() => import("./pages/UserTransactions"));
const DocsPage = lazy(() => import("./pages/DocsPage"));
const DocViewPage = lazy(() => import("./pages/DocViewPage"));

export default function Router() {
  useGoogleAnalytics();
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<AdminRoutes />}>
          {/* Admin Routes */}
          <Route element={<AdminPage />} path="/admin" />
          <Route
            element={<HandleTestAccess />}
            path="/admin/handle-tests-access"
          />
          <Route
            element={<HandleCourseAccess />}
            path="/admin/handle-courses-access"
          />
          {/* to view all course progress */}
          <Route element={<AdminCoursesPage />} path="/admin/courses" />
          <Route
            element={<AdminChapterProgressView />}
            path="/admin/courses/:username/:slug"
          />
          <Route
            element={<AdminContentProgressPage />}
            path="/admin/courses/:username/:courseSlug/:chapterSlug"
          />
        </Route>

        <Route element={<PrivateRoutes />}>
          {/* Tests Routes Protected */}
          <Route element={<TestBoardPage />} path="/test/:id" />
          <Route
            element={<TestResultDetailsPage />}
            path="/test_results/view/:attemptId"
          />
          <Route
            element={<TestResultListPage />}
            path="/test_results/:testId"
          />
          {/* User Routes Protected */}
          <Route element={<UpdateProfilePage />} path="/profile" />
          <Route element={<UserDashboardPage />} path="/user/dashboard" />
          <Route element={<UserTransactions />} path="/user/transactions" />
          <Route element={<UpdateProfilePage />} path="/user/update/info" />
          <Route element={<UpdateProfilePage />} path="/user/update/college" />
          <Route element={<UpdateProfilePage />} path="/user/update/company" />
          <Route
            element={<UpdateProfilePage />}
            path="/user/update/job-status"
          />
          <Route
            element={<UpdateProfilePage />}
            path="/user/email-preferences"
          />
          <Route element={<UpdateProfilePage />} path="/user/change-password" />

          {/* User Tests Routes */}
          <Route element={<UserTestsPage />} path="/user/tests" />

          {/* User Courses Routes */}
          {/* <Route element={<UserCoursesPage />} path="/user/courses" /> */}
          {/* <Route element={<UserChaptersPage />} path="/user/courses/:slug" /> */}
          {/* <Route
            element={<UsersContentsPage />}
            path="/user/courses/:courseSlug/:chapterSlug"
          /> */}
          {/* <Route
            element={<UserContentPage />}
            path="/user/courses/:courseSlug/:chapterSlug/:contentSlug"
          /> */}
          {/* <Route
            element={<CourseTest />}
            path="/user/courses/:courseSlug/:chapterSlug/:contentSlug/test/:testId/:contentProgressID"
          /> */}

          {/* User Review Routes */}
          <Route element={<TestReviewPage />} path="/user/review/:type/:slug" />

          {/* Purchases Route Protected */}
          <Route element={<PaymentCompletion />} path="/payment-status" />
          <Route element={<PurchasePage />} path="/purchase/:type/:slug" />
        </Route>

        {/* Base App Routes */}
        <Route element={<Home />} path="/" exact />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<SignUpPage />} path="/sign-up" />
        <Route element={<AboutPage />} path="/about" />
        <Route element={<ServicesPage />} path="/services" />
        <Route element={<ContactPage />} path="/contact" />
        <Route element={<FAQPage />} path="/faq" />
        <Route element={<PrivacyPolicy />} path="/privacy-policy" />
        <Route element={<TermsAndConditions />} path="/terms-and-conditions" />
        <Route
          element={<CancellationOrRefund />}
          path="/cancellation-or-refund"
        />

        {/* User Routes Not Protected */}
        <Route element={<VerifyEmail />} path="/verify-email/:key" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route
          element={<ResetPassword />}
          path="/reset-password-confirm/:uid/:token"
        />
        <Route element={<UnSubscribeEmail />} path="/unsubscribe" />

        {/* Tests Routes Not Protected  */}
        <Route element={<TestsPage />} path="/tests" />
        <Route element={<TestDetailPage />} path="/tests/:testSlug" />

        {/* Courses Routes Not Protected */}
        {/* <Route element={<CoursesPage />} path="/courses" /> */}
        {/* <Route element={<CourseDetailPage />} path="/courses/:slug" /> */}

        {/* Certificate Routes */}
        <Route element={<CertificatePage />} path="/certificate/:id" />

        {/* Docs Routes */}
        <Route element={<DocsPage />} path="/docs" />
        <Route element={<DocViewPage />} path="/docs/:slug" />

        {/* Other Routes */}
        <Route element={<VerifSudha />} path="/curiosity" />
        <Route element={<VimeoVideos />} path="/vimeo-videos" />
        <Route element={<SafalMetaVLSILogin />} path="/metavlsi_sfal_login" />
        <Route element={<SafalMetaVLSISignUp />} path="/metavlsi_sfal_signup" />

        {/* Error Pages */}
        <Route element={<PageNotFound />} path="*" />
      </Routes>
    </Suspense>
  );
}

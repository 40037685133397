import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuthStore from "../store/authStore";
import Loading from "../components/common/components/Loading";

const AdminRoutes = () => {
  let { user, isAuthenticated } = useAuthStore();
  const location = useLocation();

  if (isAuthenticated && user === null) {
    return <Loading />;
  }

  return isAuthenticated ? (
    user?.is_superuser === true ? (
      <Outlet />
    ) : (
      // <Navigate to={`/`} />
      <h1>Not Authorized</h1>
    )
  ) : (
    <Navigate to={`/login?next=${location.pathname}`} />
  );
};

export default AdminRoutes;

export const API_URLS = {
  signUp: "auth/registration/",
  verifyEmail: "auth/registration/verify-email/",
  login: "auth/login/",
  logout: "auth/logout/",
  user: "users/me/",
  testList: "tests/",
  attempt: "attempt/",
  testDetail: (testId) => `tests/${testId}/`,
  submitTest: "submit_test/",
  testResult: "test_results/",
  viewResult: "ans_sheets/",
  unSubscribeEmail: "mailer/unsubscribe/",
};

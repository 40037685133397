import create from "zustand";
import { API_URLS } from "../config/url.config";
import { config } from "../config/header.config";
import DB from "../utils/storage";
import axios from "../utils/axios";

const useAuthStore = create((set, get) => ({
  user: null,
  profile: null,
  tests: null,
  courses: null,
  reviews: null,
  isAuthenticated: DB.getToken() == null ? false : true,
  loading: false,
  token: DB.getToken(),
  error: null,
  emailSent: false,

  login: async (username, password) => {
    set({ loading: true });

    try {
      const res = await axios.post(API_URLS.login, {
        username,
        password,
      });
      // console.log("response ", res.data);
      set({ isAuthenticated: true });
      set({ token: res?.data?.key });

      set({ error: null });
      DB.setToken(res?.data?.key);
    } catch (err) {
      set({ error: err?.response?.data ?? "Some error occurred" });
      throw err?.response?.data ?? "Some error occurred";
    } finally {
      set({ loading: false });
    }
  },

  logout: async () => {
    try {
      set({ loading: true });
      await axios.post(API_URLS.logout, {}, config);
      set({ isAuthenticated: false, token: null, user: null });

      DB.removeToken();
    } catch (err) {
      throw err;
    } finally {
      set({ loading: false });
    }
  },

  getUser: async () => {
    try {
      let { data } = await axios.get(API_URLS.user, config(DB.getToken()));
      set({
        user: data.user,
        profile: data.profile,
        tests: data.tests,
        courses: data.courses,
        reviews: data.reviews,
      });
      // console.log(data);
    } catch (err) {
      set({ isAuthenticated: false, token: null, user: null });
      throw err;
    }
  },

  getReview: (reviewType, objectId) => {
    return get().reviews?.filter(
      (review) =>
        review.review_type === reviewType && review.object_id === objectId
    )[0];
  },

  updateUser: async (user) => {
    try {
      let { data } = await axios.patch(
        API_URLS.user,
        user,
        config(DB.getToken())
      );
      set({ user: data });
    } catch (err) {}
  },

  signUp: async (user) => {
    try {
      set({ loading: true });
      let { data } = await axios.post(API_URLS.signUp, user);
      // console.log(data);
      set({
        emailSent: data?.detail === "Verification e-mail sent." ? true : false,
      });
      return data;
    } catch (err) {
      throw err?.response?.data ?? "Some error occurred";
    } finally {
      set({ loading: false });
    }
  },
}));

export default useAuthStore;

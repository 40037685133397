import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

const TRACKING_ID = "UA-249404663-1";
const Measurement_ID = "G-M409LWTS7K";

function init() {
  // Enable debug mode on the local development environment

  const isDev =
    ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
      process.env.REACT_APP_DEBUG === "true") ||
    false;
  // ReactGA.initialize(TRACKING_ID, { debug: isDev });
  ReactGA4.initialize(Measurement_ID, { debug: isDev });
}

function sendEvent(payload) {
  // console.log("payload", payload);
  // ReactGA.event(payload);
  ReactGA4.event(payload);
}

function sendPageView(path) {
  // ReactGA.set({ page: path });
  // ReactGA.pageview(path);
  // ReactGA.send({ hitType: "pageview", page: path });
  ReactGA4.set({ page: path });
}

export const Analytics = {
  init,
  sendEvent,
  sendPageView,
};

export default Analytics;

import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Router from "./Router";
import useAuthStore from "./store/authStore";

import Alert from "./components/common/components/Alert";
const queryClient = new QueryClient();

function App() {
  const { isAuthenticated, user, getUser } = useAuthStore();

  useEffect(() => {
    if (isAuthenticated === true && user === null) {
      getUser();
    }
  }, [isAuthenticated, user, getUser]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Alert message={'Please complete all tests and courses by May 20, 2024. Thank you.'}/>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </QueryClientProvider>
    </div>
  );
}

export default App;

import { Outlet, Navigate, useLocation } from "react-router-dom";
import useAuthStore from "../store/authStore";

const PrivateRoutes = () => {
  let { isAuthenticated } = useAuthStore();
  const location = useLocation();
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?next=${location.pathname}`} />
  );
};

export default PrivateRoutes;

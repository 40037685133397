import React from "react";
import { useLocation } from "react-router-dom";

import Analytics from "../utils/analytics";

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    Analytics.init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    // console.log("currentPath", currentPath);
    Analytics.sendPageView(currentPath);
  }, [location]);
}
